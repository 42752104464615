"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));

var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));

var Account = _interopRequireWildcard(require("@/model/user/account"));

var _vuex = require("vuex");

var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));

var _stationSetting = require("@/assets/js/stationSetting");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: {
    data: Array
  },
  data: function data() {
    return {
      getShowHideMenu: Account.getShowHideMenu,
      isCollapse: false,
      is8Coin: false,
      isMaskex: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    userInfo: "user/userInfo",
    language: "user/language",
    environment: "user/environment"
  })),
  created: function created() {
    console.log("VUE_UNION_STATION:", _stationSetting.VUE_UNION_STATION);

    if (_stationSetting.VUE_UNION_STATION == "8coin") {
      this.is8Coin = true;
    }

    if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.isMaskex = true;
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    goToPage: function goToPage(path) {
      this.$router.push({
        path: path
      });
    },
    handleOpen: function handleOpen(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
exports.default = _default;